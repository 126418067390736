header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    background-color: black;
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.29);
    border-bottom: 5px solid rgb(26, 105, 53);

    font-size: 14px;
    color: #ffffffcc;

    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 100;

    .left {
        .logo {
            margin-right: 32px;
            font-size: 24px;
            font-weight: 700;

            .sub-text {
                font-size: 12px
            }
        }
    }

    .center {
        display: flex;
        align-items: center;

        .menu {
            margin-right: 24px;

            .menu-button {
                border: none;
                background-color: transparent;

                img {
                    width: 38px;
                    height: 38px;
                }
            }
        }

        .search {
            position: relative;
            margin-right: 16px;

            input {
                border: 0;
                border-radius: 2px;
                width: 500px;
                padding-left: 7px;
                height: 30px;
                background-color: #86868b;
            }

            .icon {
                position: absolute;
                width: 24px;
                height: 24px;

                top: 5px;
                right: 10px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .cart-pc {
            display: block;
            margin-right: 48px;

            .cart-icon-wrapper {
                position: relative;

                .cart-count {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 50%;
                    padding: 1px 5px;
                    color: black;
                    background-color: orange;
                    z-index: 100;
                }
            }
        }

        .cart-mobile {
            display: none;
            margin-right: 0;

            .cart-icon-wrapper {
                position: relative;

                .cart-count {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 50%;
                    padding: 1px 5px;
                    color: black;
                    background-color: orange;
                    z-index: 100;
                }
            }
        }
    }

    .right {
        .contacts {
            display: flex;
            align-items: center;
            gap: 10px;

            .phone-number {
                color: white;
            }
        }
    }

    @media (max-width: 1200px) {
        .left {
            display: none;
        }
    }

    @media (max-width: 950px) {
        .right {
            display: none;
        }
    }

    @media (max-width: 750px) {
        .center {
            .search {
                input {
                    width: 200px;
                }
            }

            .cart-pc {
                display: none;
            }

            .cart-mobile {
                display: block;
            }
        }

    }
}