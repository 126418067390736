.cart-row {
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(175, 175, 175);
    margin-bottom: 15px;


    .product-image {
        width: 150px;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: 550px) {
        font-size: 14px;
        text-wrap: wrap;

        .product-code {
            width: min-content;
        }

        .total-row-price {
            display: none;
        }

        .single-row-price {
            text-wrap: wrap;
            width: min-content;
        }

        .product-image {
            width: 90px;
        }
    }   
}