.thanks-page-container {
    width: 70%;
    padding-top: 40px;
    color: black;

    p.thanks-text-title {
        text-align: center;
        font-size: 24px;
        padding-top: 50px;
        margin: 0 auto;
    }

    p.thanks-text {
        text-align: center;
        font-size: 18px;
        padding-top: 10px;
        color: rgb(73, 73, 73);
        margin: 0 auto;
    }
}