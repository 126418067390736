.call-now-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: black;

    display: none;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;

    z-index: 100;
    
    -webkit-animation: switch 4s linear infinite;
    @media (max-width: 550px) {
        display: flex;
    }
}

.call-now-button:hover {
    transform: scale(1.1);
}

.contact-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: black;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;

    z-index: 100;
    
    -webkit-animation: switch 4s linear infinite;
    @media (max-width: 550px) {
        display: none;
    }
}

.contact-button:hover {
    transform: scale(1.1);
}

@keyframes fadeIn {
    0% {
        transform: translateX(100px);
    }

    50% {
        transform: translateX(100px);
    }

    80% {
        transform: translateX(-15px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes switch {
    0% {
        transform: scale(1.0);
    }

    45% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.1);
    }

    55% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.0);
    }
}