.production-page-container {
    width: 70%;
    padding-top: 40px;
    padding-bottom: 50px;
    line-height: 1.8;
    color: black;

    .production-page-title {
        margin-bottom: 18px;
    }

    .production-page-content {
        margin-bottom: 50px;
    }

    @media (max-width: 550px) {
        width: 90%;
    }
}