.icon-button {
    background-color: transparent;    
    transition: transform 0.3s ease-in-out;
}

.icon-button:hover {
    transform: scale(1.1);
}


  