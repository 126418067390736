.block {
    position: relative;
    border-radius: 12px;
    min-width: 350px;

    .block-image {
        position: absolute;
        min-width: 350px;
    }
    .block-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 10;
    }
}

.box {
    background-color: white;
}