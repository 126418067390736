.categories-page-container {
    width: 70%;
    padding-top: 40px;
    margin-bottom: 70px;
    color: black;

    .navigation {
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 30px;
    }

    h4 {
        margin-bottom: 16px;
    }

    .category-block {
        box-shadow: 4px 4px 16px -7px rgba(0,0,0,0.75);
        background-repeat: no-repeat;
        background-position: 50% 90%;
        background-size: 70%;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        cursor: pointer;
        height: 200px;
        padding: 20px;
        position: relative;
        text-align: center;
        transition: all ease-in 0.3s;

        .title {
            text-transform:uppercase;
            margin-top: 10px;
            color: black;
            font-weight: 600;
        }

        &:hover {
            box-shadow: 4px 4px 16px -3px rgba(0,0,0,0.75);
        }
    }

    @media (max-width: 550px) {
        width: 90%;
    }
}