.filter-block {
    width: 300px;
    min-height: 80vh;
    border-right: 1px solid rgb(209, 209, 209);


    .filter {
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(192, 192, 192);

        .title {
            font-size: 18px;
            font-weight: 700;
            padding: 10px;
        }

        ul {
            list-style-type: none;
            padding-left: 10px;
            padding-bottom: 10px;

            li {
                padding: 10px;
            }
        }

        .price-input-group {
            display: flex;
            gap: 8px;
            padding: 0 10px;
            margin-bottom: 10px;

            input {
                width: 50%;
                padding: 10px;
                border-radius: 5px;
            }
        }
    }

    &.show {
        display: block;
    }

    .close-button {
        display: none;
        position: absolute;
        font-size: 35px;
        right: 20px;
        top: 10px;
    }

    @media (max-width: 550px) {

        position: absolute;
        background-color: white;
        top: 75px;
        left: 0;
        display: none;
        overflow-y: auto;

        .close-button {
            display: block;
        }
    }
}