footer {
    width: 100%;
    height: min-content;
    color: white;
    background-color: black;

    .footer-wrapper {
        display: flex;
        align-items: center;
        height: min-content;
        justify-content: center;
        padding: 40px;
        gap: 15px;

        .footer-contact {
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-self: flex-start;
            width: 25%;

            .contact-item {
                display: flex;
                align-items: center;
                gap: 12px;

                .column {
                    display: flex;
                    gap: 10px;
                    flex-direction: column;
                }
            }
        }
    }

    .developed-by {
        width: 100%;
        text-align: center;
        padding: 20px 0 ; 
        font-size: 20px;
        
        a {
            font-weight: 700;
        }
    }

    @media (max-width: 1000px) {
        .footer-wrapper {
            padding: 40px 10px;
            padding-bottom: 30px;
        }
    }

    @media (max-width: 700px) {
        .footer-wrapper {
            font-size: 12px;
            .logo-footer-menu {
                img {
                    width: 70px;
                }
            }

            .footer-contact {
                width: 80%;
            }
        }
    }

}