.component-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
    font-weight: 600;
    border-radius: 8px;


}

.primary-button {
    color: white;
    background-color: black;
}

.secondary-button {
    color: black;
    background-color: white;
    border: 1px solid black;
}