.top-container {
    position: relative;
    width: 100%;
    padding-top: 80px;
    background-color: black;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    .wrapper {
        display: flex;
        align-items: end;
        height: 100%;
        width: 80%;
        max-width: 1500px;
        justify-content: center;

        .text {
            margin-bottom: 90px;
            padding-left: 20px;
            margin-right: -120px;
            width: min-content;
            color: white;
            border-left: 3px solid whitesmoke;

            .sub-text {
                font-size: 18px;
                width: 80%;
            }
        }

        .angel {
            z-index: 10;

            img {
                width: 110%;
                min-width: 500px;
                max-width: 900px;
                filter: brightness(70%)
            }
        }

        @media (max-width: 550px) {
            flex-direction: column;
            align-items: start;
            font-size: 50%;

            .text {
                h1 {
                    font-size: 40px;
                }
            }
        }
    }
}

.block-container {
    position: relative;
    width: 100%;
    z-index: 2;

    .products-image-wrapper {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 50px 50px;
        padding-bottom: 70px;
        border-top: 1px solid rgb(194, 194, 194);
    }

    .granite-image-wrapper {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 50px 50px;
        padding-bottom: 70px;

        .types-wrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            gap: 20px;

            .granite-type {
                position: relative;
                width: 30.33%;

                .granite-name {
                    color: white;
                    font-size: 20px;
                    font-weight: 600;
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    transition: all ease 0.7s;
                    z-index: 10;
                }

                .type-desc {
                    width: 90%;
                    color: white;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1.8;
                    position: absolute;
                    top: 50px;
                    left: 20px;
                    opacity: 0;
                    transition: all ease 0.6s;
                }


                img {
                    width: 100%;
                    transition: all ease 0.6s;
                    filter: contrast(75%);
                }

                &:hover {
                    cursor: pointer;
                }

                &:hover>.granite-name {
                    text-decoration: underline;
                }

                &:hover>.type-desc {
                    visibility: visible;
                    opacity: 1;
                }

                &:hover>img {
                    filter: brightness(0.6) blur(0.3px);
                }

                @media (max-width: 700px) {
                    width: 100%;
                    max-width: 500px;
                }
            }
        }
    }

    .advantages-wrapper {
        width: 90%;
        max-width: 1500px;
        margin: 50px auto;
        display: flex;
        justify-content: center;

        .advantage-item {
            display: flex;
            align-items: center;
            gap: 15px;
            color: white;
            background-color: rgba(255, 255, 255, 0.168);
            min-height: 160px;
            padding: 20px;
            transition: all 0.3s ease-in;

            .advantage-title {
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 10px;
            }

            .advantage-desc {
                font-size: 14px;
                line-height: 1.5;
            }

            &:hover {
                background-color: rgba(245, 245, 245, 0.303);
            }
        }
    }

    .production-info-wrapper {
        width: 90%;
        max-width: 1500px;
        margin: 50px auto;
        display: flex;
        justify-content: center;
    }

    p.desc-section {
        width: 80%;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.5;
        margin: 0 auto;
        text-align: left;
        color: whitesmoke;
    }

    a.more-detail-link {
        color: whitesmoke;
        text-align: center;
        margin-top: 20px;
    }
}

h2.section-title {
    color: white;
    font-size: 28px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 40px;
}

.background-container {
    position: absolute;
    height: 100%;
    min-width: 100%;
    z-index: -1;
    filter: brightness(0.2) contrast(0.85);
}