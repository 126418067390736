.product-cart {
    height: 410px;
    width: 100%;
    background-color: white;
    border-radius: 12px;
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.29);

    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    .product-image {
        height: 50%;
        text-align: center;
        padding: 10px;

        img {
            max-width: 100%;
            height: 100%;
        }
    }

    .product-desc-wrapper {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .product-price {
            padding: 10px;
            text-align: center;
            font-size: large;
            font-weight: 700;
        }

        .product-title {
            font-weight: 600;
        }

        .product-desc {
            padding: 10px;
            font-size: 12px;

            .row {
                padding: 5px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgb(214, 214, 214);
            }

            .row-value {
                text-align: end;
            }
        }
    }


    .buttons {
        display: flex;
        align-items: end;
        margin-top: 10px;

        .in-cart {
            padding: 0 10px;
            border: 3px solid black;
            border-radius: 3px;
        }

        .order-by {
            text-align: center;
            width: 100%;
            padding: 12px;
            color: white;
            background-color: black;
            border-radius: 0 0 12px 12px;
        }
    }

    @media (max-width: 1250px) {}

    @media (max-width: 1000px) {}

    @media (max-width: 500px) {
        height: 500px;

        .product-image {
            height: 55%;
        }

        .product-price {
            font-size: 22px;
        }

        .product-desc {
            font-size: 16px;
        }

        .buttons {
            .order-by {
                padding: 15px;
            }
        }
    }
}

.product-cart:hover {
    box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.29);
}