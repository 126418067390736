.detail-product-container {
    width: 90%;
    padding-top: 20px;
    color: black;
    
    .navigation {
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .product-block {
        width: 100%;
        display: flex;
        gap: 50px;

        .product-image {
            width: 50%;

            .product-image-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                gap: 20px;

                .arrow-wrapper {
                    cursor: pointer;
                }

                .arrow-wrapper.left {
                    rotate: 180deg;
                }

                img.single-product-image {
                    width: 100%;
                }
                img.product-image {
                    display: none;
                    width: 100%;
                    height: auto;
                    transition: all 0.5s ease-in-out;
                    opacity: 0;
                }

                img.product-image.active {
                    display: flex;
                    opacity: 1;
                }
            }

            .contact-phone-block {
                width: 100%;
                margin: 0 auto;
                margin-top: 140px;
                text-align: center;
                font-size: 34px;

                .sub-text-contact {
                    font-size: 24px;
                    color: rgb(87, 87, 87);
                }
            }
        }

        .product-detail {
            width: 50%;
            padding-top: 50px;

            .price {
                margin-top: 40px;
                font-size: 26px;
                font-weight: 700;
            }

            .desc {
                line-height: 2.0;
                font-size: 20px;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .line {
                padding: 20px 0px;
                width: 100%;
                border-bottom: 1px solid gray;
            }

            .conditions {
                margin-top: 40px;
                .temp {
                    padding: 15px;
                    line-height: 1.5;
                    display: flex;
                    gap: 20px;
                }
            }

            .button-group {
                width: 100%;
                display: flex;
                gap:10px;

                .btn-container {
                    width: 100%;
                }

                .select-button {
                    padding: 10px 20px;
                    border: 1px solid black;
                    background-color: transparent;
                    border-radius: 5px;
                    transition: 0.3s all;

                    &.active {
                        color: whitesmoke;
                        background-color: black;
                    }
                }
            }

            .info-table {
                margin-bottom: 40px;
                margin-top: 40px;
            }

            .additional-items {
                margin-bottom: 30px;
                white-space: pre-wrap;
            }
        }

        @media (max-width: 920px) {
            flex-flow: column;

            .product-detail{
                width: 100%;
            }

            .product-image {
                width: 100%;
            }
        }
    }
}