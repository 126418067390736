.product-container-wrapper {
    margin-bottom: 20px;
    width: 80%;


    .product-container {
        width: 100%;
        display: grid;

        grid-template-columns: repeat(4, calc(25% - 15px));

        gap: 15px;



        @media (max-width: 1350px) {
            grid-template-columns: repeat(3, 33%);
            /* Display two columns on smaller screens */
        }

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 50%);
            /* Display two columns on smaller screens */
        }

        @media (max-width: 700px) {
            grid-template-columns: repeat(1, 400px);
            /* Display two columns on smaller screens */
        }

        @media (max-width: 550px) {
            grid-template-columns: repeat(1, 100%);
            /* Display two columns on smaller screens */
        }
    }

    @media (min-width: 550px) {
        margin-left: 30px;
    }

    @media (max-width: 1250px) {
        width: 80%;
        grid-template-columns: repeat(3, 33%);
        /* Display two columns on smaller screens */
    }

    @media (max-width: 1000px) {
        width: 80%;
        grid-template-columns: repeat(2, 50%);
        /* Display two columns on smaller screens */
    }

    @media (max-width: 700px) {
        width: 80%;
        grid-template-columns: repeat(1, 400px);
        /* Display two columns on smaller screens */
    }

    @media (max-width: 550px) {
        width: 95%;
        grid-template-columns: repeat(1, 100%);
        /* Display two columns on smaller screens */
    }
}




.gallery-wrapper {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;

    h3 {
        font-size: 24px;
        margin-bottom: 15px;

        @media (max-width: 550px) {
            font-size: 18px;
        }
    }

    p {
        color: gray;
        margin-bottom: 30px;
    }

    .slider-wrapper {
        display: none;
        margin: 0 auto;

        &.pc {
            @media (min-width: 700px) {
                display: block;
            }
        }

        &.mobile {
            @media (max-width: 700px) {
                width: 100%;
                display: block;
            }
        }
    }

    #slider-container {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
    }

    #slider-image {
        object-fit: cover;
        width: auto;
        height: 100%;
    }
}