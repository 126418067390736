.cart-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-wrapper {
    position: absolute;
    left: 8%;
    top:60px;
    color: black;
    width: 80%;
    max-height: 600px;
    background-color: white;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.29);

    padding: 30px;

    h3 {
        font-size: 24px;
        margin-bottom: 34px;
    }

    .cart-overflow {
        max-height: 450px;
        padding-right: 20px;
        overflow-y: auto;
    }

    .button-group {
        font-size: large;
        margin-top: 20px;
        gap: 50px;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .plug {
        text-align: center;
        font-size: large;
    }
}

.close {
    display: none;
    animation: slide-in 1s ease-in-out 0.5s 1 forwards;
}


.cart-overflow::-webkit-scrollbar {
    width: 7px;
    /* Ширина полосы прокрутки */
    background-color: transparent;
    /* Цвет фона полосы прокрутки */
}

.cart-overflow::-webkit-scrollbar-track {
    background: transparent;
}

/* Изменение цвета полосы прокрутки при наведении */
/* Например, для полосы прокрутки в цвете #666 */
.cart-overflow::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(45, 45, 45);
    /* Цвет полосы проrgb(55, 55, 55)и */
}

.cart-overflow::-webkit-scrollbar-thumb:hover {
    background-color: rgb(94, 94, 94);
}