.contact-page-container {
    width: 70%;
    padding-top: 40px;
    color: black;

    .contact-page-title {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 29px;
    }

    .contact-wrapper {
        display: flex;

        .map {
            z-index: 10;
            width: 60%;
            background-color: white;

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .contact-items-container {

            .contact-item {
                margin-left: -30px;
                padding: 20px;
                padding-left: 60px;
                border: 1px solid green;
                border-radius: 12px;
                margin-bottom: 20px;
                line-height: 1.5;

                .contact-item-value {
                    color: gray;
                }
            }

            .contact-item:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }

        @media (max-width: 550px) {
            flex-direction: column;

            .map {
                width: 100%;
                margin-bottom: 20px;
            }

            .contact-items-container {
                .contact-item {
                    margin-left: 0;
                }
            }
        }
    }

    @media (max-width: 1000px) {
        width: 90%;
    }
}