.modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;

    animation: smooth 1s;
    z-index: 100;
}

/* Styling for the modal content */
.modal-content {
    position: relative;
    background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 55%, rgba(237,237,237,1) 100%);
border-left: 10px solid green;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    max-width: 400px;

    .close-button {
        position: absolute;
        top: 10px;
        right: 15px;
        background-color: transparent;
    }

    .modal-paragraph {
        font-size: 14px;
        text-wrap: wrap;
        margin: 0 auto;
        margin-top: 16px;
        color: gray;


    }

    input {
        width: 80%;
        margin-top: 10px;
        border-radius: 3px;
        font-size: 16px;
        padding: 10px;
    }
}

/* Animation styles for the modal */
.modal-container.active {
    display: flex;
    opacity: 1;
}

.modal-content.active {
    transform: scale(1);
}

.send-button {
    margin-top: 20px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 10px;
    padding: 10px;
}


@keyframes smooth {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}