.cooperation-page-container {
    width: 70%;
    padding-top: 40px;
    padding-bottom: 50px;
    color: black;

    .cooperation-page-title {
        margin-bottom: 18px;
    }

    .cooperation-page-content {
        margin-bottom: 50px;
    }

    h4 {
        margin-bottom: 8px;
    }

    .cooperation-item {
        margin-bottom: 32px;

        p {
            margin-bottom: 16px;
        }

        ul.monument-list {
            padding-left: 40px;
            
            li {
                margin-top: 8px;
            }
        }
    }

    @media (max-width: 550px) {
        width: 90%;
    }
}