.catalog-page-wrapper {
    width: 90%;

    .catalog-page-container {
        padding-top: 20px;
        width: 100%;
        display: flex;
        color: black;
    }

    .filter-top {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;

        .filter-icon-mobile {
            display: none;
        }
    }

    @media (max-width: 1100px) {
        width: 95%;
    }

    @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;

        .filter-top {
            justify-content: space-between  ;

            .filter-icon-mobile {
                display: block;
            }
        }
    }
}