.blocks-grid {
    width: 100%;    
    max-width: 1200px;
    height: 700px;
    display: grid;
    gap: 20px;
    grid-template-areas:
        "A A  B B"
        "A A  B B"
        "A A  C C "
        "A A  C C ";

    @media (max-width: 550px) {
        grid-template-areas:
        "A A"
        "A A"
        "B B"
        "B B"
        "C C"
        "C C";
    }
}