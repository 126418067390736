@import "react-slideshow-image/dist/styles.css";
@import url('./buttons.scss');

* {
    margin: 0;
    padding: 0;

    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 72px;
    text-wrap: wrap;
}

a {
    color: inherit;
    text-decoration: none;
}

.bold-text {
    font-weight: 700;
}

button {
    border: 0;
    cursor: pointer;
    font-weight: 600;
}

input {
    border-style: initial;
    font-size: 16px;
    border: 1px solid rgb(196, 196, 196);
}

/* Установка ширины полосы прокрутки */
/* По умолчанию: auto */
/* Принимаемые значения: thin, auto, none */
/* 'thin' уменьшает ширину полосы прокрутки */


/* Изменение цвета полосы прокрутки */
/* Принимает цвет в формате hex, rgb, или имя цвета */
/* Например, для полосы прокрутки в цвете #999 */
