.layout-background {
    position: fixed;
    top: 0px;
    z-index: -1;
    width: 100%;
    margin-top: 70px;
    min-height: 100vh;
    opacity: 0.6;
}

.content {    
    color: rgb(33, 33, 33);
    width: 100%;
    min-height: 100vh;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
     
}