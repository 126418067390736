.image-modal {
    display: none;

    .modal-background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 150vh;
        background-color: rgba(0, 0, 0, 0.556);
    }

    .modal-wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(48, 48, 48);
        width: 80%;
        padding: 30px 80px;
        z-index: 10000;
        border-radius: 20px;

        #slider-container-out {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 50%;
            min-height: 300px;

        }

        @media (max-width: 700px) {
            width: 90%;
            padding: 20px;

            #slider-container {
                width: 100%;
            }

        }

        #slider {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }

        .indicator {
            cursor: pointer;
            gap: 10px;

            transition: all 0.3s ease-in-out;

            .mini-photo {
                width: 50px;
                height: 50px;
            }

            .pc {
                width: 50px;
                height: 50px;
                display: none;
                margin-right: 20px;
                margin-top: 20px;

                @media (min-width: 700px) {
                    display: block;
                }
            }

            .mobile {
                display: none;
                font-size: 30px;
                margin-right: 10px;
                color: rgb(102, 102, 102);

                @media (max-width: 700px) {
                    display: block;
                }
            }
        }

        .indicator.active {
            .pc {
                color: #fff;
                border: 1px solid white;
            }

            .mobile {
                color: #fff;
                font-size: 30px;
            }
        }

        .indicator:hover {
            .pc {
                filter: blur(1.5px);
            }
        }
    }
}