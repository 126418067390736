.order-container {
    width: 70%;
    padding-top: 20px;
    color: black;

    .navigation {
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .order-page-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        h3.title {
            margin-bottom: 34px;
        }

        .order {
            align-self: center;
            width: 100%;

            .total-price {
                text-align: end;
            }
        }

        .contact-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 100px;

            input {
                padding: 8px;
                border-radius: 5px;
            }

            .contact-info-row {
                width: 100%;
                display: flex;
                gap: 20px;

                .contact-column {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    width: 50%;
                }

                @media (max-width: 550px) {
                    flex-direction: column;

                    .contact-column {
                        width: 100%;
                    }
                }
            }
        }

        .paragraph-info {
            margin-top: 20px;
        }

        .order-button-group {
            margin: 50px 0;
            align-self: center;
        }
    }


    @media (max-width: 550px) {
        width: 90%;
    }
}