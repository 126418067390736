.catalog-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

.catalog-pc {
    display: grid;
    position: absolute;
    top: 80px;
    left: 5%;
    width: 80%;
    height: 600px;
    background-color: white;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.29);
    grid-template-areas:
        "A  B  C  D"
        "A  B  C  D";




    @keyframes slide-in {
        from {
            transform: translateY(-100%);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    // .row {
    //     width: 100%;
    //     display: flex;

    //     padding: 20px;


    // }

    .list {
        width: 90%;
        height: 250px;
        display: flex;
        flex-direction: column;
        padding: 20px;

        .title {
            color: rgb(30, 30, 30) !important;
            font-size: 18px;
            font-weight: 600;
        }

        ul {
            list-style-type: none;

            li {
                padding: 5px;

                a {
                    color: rgb(30, 30, 30) !important;
                }

                a:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    @media (max-width: 750px) {
        display: none;
    }
}

.catalog-mobile {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: white;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.29);
    grid-template-areas:
        "A  B  C  D"
        "A  B  C  D";

    .catalog-wrapper {
        position: relative;

        .catalog-logo {
            display: flex;
            justify-content: center;
            padding: 20px;
        }

        .home-icon {
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .catalog-items-wrapper {
            display: flex
        }
    }


    .title-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        color: rgb(30, 30, 30) !important;
        font-size: 18px;
        font-weight: 600;

        border-right: 1px solid rgb(214, 214, 214);

        .title {
            padding: 10px;
        }
    }

    .list {
        width: 40%;
        height: 250px;
        display: none;
        flex-direction: column;
        padding: 20px;

        .title {
            color: rgb(30, 30, 30) !important;
            font-size: 18px;
            font-weight: 600;
        }

        ul {
            list-style-type: none;

            li {
                padding: 5px;

                a {
                    color: rgb(30, 30, 30) !important;
                }

                a:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    @media (max-width: 750px) {
        display: flex;
    }
}

.close {
    display: none;
    animation: slide-in 1s ease-in-out 0.5s 1 forwards;
}