.granite-page-container {
    width: 70%;
    padding-top: 40px;
    padding-bottom: 50px;
    color: black;

    .types-granite-title {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 70px;
        font-size: 29px;
    }

    .types-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;

        .granite-type {
            position: relative;
            width: 30.33%;
            min-width: 320px;

            .granite-name {
                color: white;
                font-size: 20px;
                font-weight: 600;
                position: absolute;
                top: 20px;
                left: 20px;
                transition: all ease 0.7s;
                z-index: 10;
            }

            .type-desc {
                width: 90%;
                color: white;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.8;
                position: absolute;
                top: 50px;
                left: 20px;
                opacity: 0;
                transition: all ease 0.6s;
            }


            img {
                width: 100%;
                transition: all ease 0.6s;
            }

            &:hover {
                cursor: pointer;
            }

            &:hover>.granite-name {
                text-decoration: underline;
            }

            &:hover>.type-desc {
                visibility: visible;
                opacity: 1;
            }

            &:hover>img {
                filter: brightness(0.6) blur(0.3px);
            }

            @media (max-width: 700px) {
                width: 100%;
                max-width: 500px;
            }
        }
    }

    @media (max-width: 1000px) {
        width: 90%;
    }
}